import React from 'react';
import XLSX from 'xlsx';
import {Button} from 'antd';
import * as AppContext from '../AppContext';
import moment from 'moment';

const _headers = [
  {label: '貨品編號', key: 'item_id'},
  {label: '商品名稱', key: 'product_name'},
  {label: '規格名稱', key: 'spec_name'},
  {label: '日期', key: 'date'},
  {label: '剩餘數量', key: 'stock'},
];

const _remapOrderDataToDisplayString = (data) => {
  return {
    item_id: data.id,
    product_name: data.name,
    spec_name: data.spec.name,
    date: moment(data.spec.production_date).format('MM-DD'),
    stock: data.spec.stock,
  };
};

function ExcelProductStocksExportButton(props) {
  const {style = {}, disabled = false, filenamePostfix = '', _data} = props;
  const app = React.useContext(AppContext.Context);
  const [loading, setLoading] = React.useState(false);
  const [link, setLink] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [filename, setFilename] = React.useState('');
  const linkInstance = React.useRef();

  async function _onDownload() {
    setLoading(true);
    let _remapToDisplayData = _data.map(_remapOrderDataToDisplayString);
    let _filename = `法朋庫存${filenamePostfix ? filenamePostfix : ''}.xlsx`;
    setFilename(_filename);

    const header = _headers.map((h) => h.label);
    const rows = _remapToDisplayData.map((o) => {
      let _result = {};
      for (let h of _headers) {
        _result[h.label] = o[h.key];
      }
      return _result;
    });

    setData(rows);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(rows, {header});
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet');
    const wbout = XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
    });
    setLink(
      URL.createObjectURL(
        new Blob([wbout], {type: 'application/octet-stream'}),
      ),
    );
  }

  React.useEffect(() => {
    if (link && linkInstance && linkInstance.current) {
      let timer = setTimeout(() => {
        linkInstance.current.click();
        setLoading(false);
        setLink(null);
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [link, linkInstance]);

  return (
    <div style={style}>
      {link ? (
        <a
          href={link}
          ref={linkInstance}
          download={filename || '法朋訂單報表.xlsx'}>
          <Button loading={loading} onClick={() => 0}>
            下載
          </Button>
        </a>
      ) : (
        <Button disabled={disabled} loading={loading} onClick={_onDownload}>
          下載
        </Button>
      )}
    </div>
  );
}

export default ExcelProductStocksExportButton;
