import React, {useState} from 'react';
import styled from 'styled-components';
import {Button, Input, message, Select, Table} from 'antd';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import {validDateString} from '../../domain/Validate';
import ProductSeletorTable from './StockPreview.ProductSelectorTable';
import moment from 'moment';
import ExcelProductStocksExportButton from '../../components/ExcelProductStocksExportButton';
import _ from 'lodash';

function StockPreviewPage(props) {
  const app = React.useContext(AppContext.Context);
  const [categories, setCategories] = React.useState(null);
  const [isFetchingData, setIsFetchingData] = React.useState(false);
  const [selectCats, setSelectCats] = React.useState([]);
  const [productList, setProductList] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [filters, setFilters] = React.useState({
    date_from: '',
    date_to: '',
  });
  const [stockPreviewResult, setStockPreviewResult] = useState([]);

  React.useEffect(() => {
    const _fetchData = async () => {
      try {
        AppActions.setLoading(true);
        let cats = await app.actions.getCategories();
        setCategories(cats);
        AppActions.setLoading(false);
      } catch (err) {
        message.error('無法取得商品分類資料！');
      }
    };
    _fetchData();
  }, [app.actions]);

  const _fetchProductList = React.useCallback(
    async function (selectCats) {
      if (selectCats.length === 0) {
        console.warn('must select category to fetch products without paging');
        return [];
      }
      AppActions.setLoading(true);
      try {
        let filteredCat = selectCats.slice(-1)[0];
        let configs = {
          query: {
            $and: [{category: {$regex: `.*${filteredCat}.*`}}],
          },
          sorting: ['-updated'],
          projection: {
            id: 1,
            name: 1,
            spec: 1,
            published: 1,
          },
        };
        let _resp = await app.actions.getProducts(configs);
        setProductList(_resp);
        setSelectedProducts([]);
        setStockPreviewResult([]);
      } catch (err) {
        message.error('無法取得分類下的商品資料');
      }
      AppActions.setLoading(false);
    },
    [app.actions],
  );

  React.useEffect(() => {
    if (selectCats.length === 0) {
      return;
    }

    _fetchProductList(selectCats);
  }, [selectCats, _fetchProductList]);

  const onSearchQueryClick = async () => {
    if (!filters.date_from || !filters.date_to) {
      message.warn('請輸入日期區間！');
      return;
    }

    if (
      !validDateString(filters.date_from) ||
      !validDateString(filters.date_to) ||
      filters.date_from > filters.date_to
    ) {
      message.warn('日期區間格式有誤！');
      return;
    }

    const rangeOfSelectDate = moment(filters.date_to).diff(
      moment(filters.date_from),
      'day',
    );
    if (rangeOfSelectDate > 14) {
      message.warn('日期區間請小於 14 天');
      return;
    }

    setIsFetchingData(true);
    const stockDataByProducts = await getStockDataByProducts(selectedProducts, {
      date_from: filters.date_from,
      date_to: filters.date_to,
    });
    setStockPreviewResult(stockDataByProducts);
    if (stockDataByProducts.length === 0) {
      message.warn('您搜尋的商品在此時間間內數量皆為安全範圍(>3個可售數量)');
    }
    setIsFetchingData(false);
  };

  const getStockDataByProducts = async (products, {date_from, date_to}) => {
    const specs = products
      .map((p) => p.spec)
      .flat()
      .map((s) => s.item_id);

    const stocksInRange = await app.actions.staffGetStocks({
      item_id: {
        $in: specs,
      },
      $and: [
        {
          production_date: {
            $gte: {$date: moment(date_from).toDate().getTime()},
          },
        },
        {
          production_date: {
            $lte: {$date: moment(date_to).toDate().getTime()},
          },
        },
      ],
    });

    const result = stocksInRange
      .filter((s) => s.quantity <= 3)
      .map((cur) => {
        let _product = products.find(
          (p) => !!p.spec.find((s) => s.item_id === cur.item_id),
        );
        const spec = _product.spec.find((s) => s.item_id === cur.item_id);
        return {
          id: spec.item_id,
          name: _product.name,
          productId: _product.id,
          spec: {
            item_id: spec.item_id,
            name: spec.name,
            price: spec.price,
            stock: cur.quantity,
            production_date: cur.production_date.$date,
          },
        };
      });

    // group by the product id
    let dictionary = {};
    result.forEach((r) => {
      if (!dictionary[r.productId]) {
        dictionary[r.productId] = [];
      } else {
        dictionary[r.productId].push(r);
      }
    });

    // sorting by production date
    for (let i in dictionary) {
      dictionary[i].sort(
        (a, b) => a.spec.production_date - b.spec.production_date,
      );
    }

    return Object.values(dictionary).flat(1);
  };

  if (!categories) {
    return (
      <Wrapper>
        <h2>商品庫存預覽</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h2>商品庫存預覽</h2>
      <div className="filters">
        <div className="inputs">
          <div className="item">
            <label>庫存起始日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  date_from: e.target.value,
                })
              }
              value={filters.date_from}
            />
          </div>
          <div className="item">
            <label>庫存結束日</label>
            <Input
              placeholder={'YYYY-MM-DD'}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  date_to: e.target.value,
                })
              }
              value={filters.date_to}
            />
          </div>
          <div className="item">
            <label>類別</label>
            <CatSelectorGroup
              style={{flex: 1}}
              cats={categories.children}
              selectCats={selectCats}
              setSelectCats={setSelectCats}
            />
          </div>
        </div>
        <div className="products">
          <ProductSeletorTable
            products={productList}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        </div>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}} />
          <Button loading={isFetchingData} onClick={onSearchQueryClick}>
            搜尋
          </Button>
        </div>
      </div>

      <div className="content">
        {stockPreviewResult.length > 0 && (
          <>
            <Table
              style={{marginTop: 10}}
              dataSource={stockPreviewResult}
              columns={[
                {title: '貨品編號', dataIndex: 'id', key: 'id'},
                {title: '商品名稱', dataIndex: 'name', key: 'name'},
                {
                  title: '規格名稱',
                  dataIndex: 'spec',
                  key: 'spec_name',
                  render: (spec) => {
                    return <div>{spec.name}</div>;
                  },
                },
                {
                  title: '日期',
                  dataIndex: 'spec',
                  key: 'date',
                  render: (spec) => {
                    return (
                      <div>{moment(spec.production_date).format('MM-DD')}</div>
                    );
                  },
                },
                {
                  title: '剩餘數量',
                  dataIndex: 'spec',
                  key: 'stock',
                  render: (spec) => {
                    return <div>{spec.stock}</div>;
                  },
                },
              ]}
            />
            <ExcelProductStocksExportButton _data={stockPreviewResult} />
          </>
        )}
      </div>
    </Wrapper>
  );
}

function CatSelectorGroup(props) {
  const {level = 0, cats, setSelectCats, selectCats, style = {}} = props;

  const onCatSelectChange = (level) => {
    return (value) => {
      let _nextActiveCats = [...selectCats];
      if (!value) {
        _nextActiveCats = _nextActiveCats.slice(0, selectCats.length - 1);
      } else if (selectCats.length === level) {
        _nextActiveCats.push(value);
      } else if (selectCats.length > level) {
        _nextActiveCats = [..._nextActiveCats.slice(0, level), value];
      } else {
        // none of this condition
      }
      setSelectCats(_nextActiveCats);
    };
  };

  const childCatOfSelectCat = React.useMemo(() => {
    let foundCat = cats.find(
      (c) => selectCats[level] && c.key === selectCats[level],
    );
    return foundCat?.children || [];
  }, [cats, selectCats[level]]);

  const allowClear = React.useMemo(() => {
    // only last but not first category allow to be cleared
    if (level === selectCats.length - 1 && level !== 0) {
      return true;
    }
    return false;
  }, [selectCats, level]);
  return (
    <CatSelectorGroupWrapper style={style}>
      <Select
        allowClear={allowClear}
        placeholder="類別"
        value={selectCats[level] || undefined}
        onChange={onCatSelectChange(level)}
        style={{minWidth: 120}}>
        {cats.map((c, idx) => (
          <Select.Option key={idx} value={c.key}>
            {c.name}
          </Select.Option>
        ))}
      </Select>

      {childCatOfSelectCat.length !== 0 && (
        <CatSelectorGroup
          level={level + 1}
          cats={childCatOfSelectCat}
          selectCats={selectCats}
          setSelectCats={setSelectCats}
          style={{marginLeft: 10}}
        />
      )}
    </CatSelectorGroupWrapper>
  );
}

const CatSelectorGroupWrapper = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  padding: 20px 20px 100px;
  & > h2 {
    margin-bottom: 16px;
  }

  & > .filters {
    & > .note {
      font-size: 14px;
      color: grey;
      text-align: right;
    }
    & > .inputs {
      & > .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        & label {
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }

    & > .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  & > .content {
  }
`;

export default StockPreviewPage;
