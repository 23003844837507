import React from 'react';
import styled from 'styled-components';
import {Table} from 'antd';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '品名',
    dataIndex: 'name',
    key: 'name',
  },
];
function ProductSeletorTable(props) {
  const {products, selectedProducts, setSelectedProducts} = props;

  const _onRowSelect = React.useCallback(
    (_selectedRowKeys, _selectedRows) => {
      setSelectedProducts(_selectedRows);
    },
    [setSelectedProducts],
  );

  return (
    <ProductSeletorTableWrapper>
      <Table
        dataSource={products}
        columns={columns}
        rowKey={(record) => record.id}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedProducts.map((p) => p.id),
          onChange: _onRowSelect,
        }}
      />
    </ProductSeletorTableWrapper>
  );
}

const ProductSeletorTableWrapper = styled.div``;

export default ProductSeletorTable;
